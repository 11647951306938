export default class FakeLogin {

    constructor() {
        this.username = 'HelsanaForesight',
        this.password = 'Helsana_Foresight2020!',
        this.submitButton = document.querySelector('.js-submit'),
        this.form = document.querySelector('.js-form');
        this.error = document.querySelector('.js-error');
        this.usernameInput = document.querySelector('.js-input-username');
        this.passwordInput = document.querySelector('.js-input-password');
        if( this.submitButton && this.form ) {
            this.init()
        }
        
    }

    init() {
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });

        this.submitButton.addEventListener('click', () => {
            let usernameInputValue = this.usernameInput.value;
            let passwordInputValue = this.passwordInput.value;

            this.compareLoginCredentials(usernameInputValue, passwordInputValue);
        });

        this.usernameInput.addEventListener('focus', () => {
            this.error.classList.remove('is-visible');
        });
        
        this.passwordInput.addEventListener('focus', () => {
            this.error.classList.remove('is-visible');
        });

        
        
    }

    compareLoginCredentials(usernameInputValue, passwordInputValue) {
        if( this.username === usernameInputValue && this.password === passwordInputValue ) {
            // Redirect to radar page
            window.location.href = "radar.html";
        } else {
            this.error.classList.add('is-visible');
        }
    }
}