export default class OverlayController {
    
    isOpen = false;

    constructor() {
        this.overlay;
        this.links = Array.from(document.querySelectorAll('.js-radar-item'));
        this.init();
    }

    init() {
        this.overlay = document.querySelector('.js-overlay');
        // Add EventListeners
        Array.from(this.links).forEach((link) => {
            link.addEventListener('click', () => {
                this.handleOverlay(link);
            });
        });
    }

    handleOverlay(link) {
        
        if( this.isOpen === true ) {

        } else {
            let title = link.textContent;
            this.openOverlay(title);
        }
        this.overlay.querySelector('.js-close').addEventListener('click', () => {
            this.closeOverlay();
        });
    }

    openOverlay(title) {
        this.overlay.querySelector('.e-heading--h2').textContent=title;
        this.overlay.classList.add('is-open');
        this.isOpen = true;

    }

    closeOverlay() {
        this.overlay.classList.remove('is-open');
        this.isOpen = false;
    }
}